import StorageService from 'common/services/storage.js'
export default {
    props: ['url','routeFrom'],   
    data: 
        function () {
                    return {
                                CONSTANTS: 
                                {
                                },
                                loading: true,
                                loadedRouteFrom: ''

                    }
                },
     created: 
        function () {
            this.init();
        },
     methods:
        {
            init: function()
            {
                var webviewParams = StorageService.getValue(localStorage, 'webviewParams');
                if(webviewParams)
                {
                    webviewParams = JSON.parse(webviewParams);
                    this.url = webviewParams.url;
                    this.loadedRouteFrom = webviewParams.routeFrom;
                }
                else
                {
                    this.loadedRouteFrom = this.routeFrom;
                }
            },       
            back: function()
            {
                this.eventHub.$emit('goTo',this.loadedRouteFrom);
                this.eventHub.$emit('changeOrientation', 'portrait');
            },   
        }                      

}